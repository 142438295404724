* {
  box-sizing: border-box;
  overscroll-behavior: none;
  user-select: none;
}

html,
body,
#root {
  /* width: 100vh; */
  height: 100vh;
  margin: 0;
  padding: 0;
}

canvas {
  position: fixed;
  height: 100vh;
}
