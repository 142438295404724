@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

.container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  font-family: 'Barlow Condensed', sans-serif;
  /* pointer-events: none; */
  width: 100%;
}

.main-wrapper {
  width: 1200px;
  margin: 0 auto;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.005rem 30px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0); /* Semi-transparent background */
  z-index: 9999; /* Ensure the header stays on top of other content */
  pointer-events: all;
  transition: backdrop-filter 0.3s ease-in-out; /* Smooth transition for blur effect */
}

/* Initial state without blur */
header.no-blur {
  backdrop-filter: none;
}

/* When scrolled, apply the blur */
header.blur {
  backdrop-filter: blur(10px); /* Adjust the blur value as needed */
    background-color: rgba(0, 0, 0, 0.1); /* Semi-transparent background */
}


.brand {
  display: flex;
  align-items: center;
  gap: 6px;
  letter-spacing: -1px;
  color: #05309c;
  font-size: 1.4em;
}

header ul {
  list-style: none;
  display: flex;
  gap: 30px;
color: #1e3d4d;
  font-weight: 400;
  font-size: 1em;
  text-transform: uppercase;
}

header ul li {
  cursor: pointer;
  padding: 6px 15px;
  border-radius: 50px;
  border: 1px solid transparent;
}

header ul li:hover {
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

button {
  background-color: #4498f8;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  font-size: 1em;
  font-weight: 600;
  color: white;
  font-family: 'Barlow Condensed', sans-serif;
  transition: all 0.3s ease;
  cursor: pointer;
}

button:hover {
  background-color: #3b6275;
  color: white;
}

.section {
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-1 {
  align-items: flex-end;
  padding-bottom: 4em;
}

.section-1 .wrapper {
  margin-top: 80px;
  width: 730px;
  text-align: center;
}

strong {
  font-weight: 500;
}

.section-1 .wrapper h2 {
  font-size: 5em;
  font-weight: 400;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  line-height: 0.9em;
  padding: 0;
  margin-bottom: 20px;
  color: #05309c;
}

.wrapper p,
.section-3 p {
  font-size: 1.3em;
  font-family: 'Roboto Flex', sans-serif;
  font-weight: 400;
  line-height: 1.6em;
 color: #1e3d4d;
}

.section-3 {
  margin-top: 60px;
  align-items: flex-end;
  padding-bottom: 4em;
}

.section-3 .wrapper ul {
  font-size: 1.3em;
  font-weight: 300;
  line-height: 1.6em;
  padding: 20px;
  font-family: 'Roboto Flex', sans-serif;
color: #1e3d4d;
}

.section-2 {
  justify-content: flex-start;
  padding-left: 4em;
  align-items: center;
}

.section-2 .wrapper {
  margin-top: 60px;
  width: 600px;
}

.section-3 .wrapper {
  margin-top: 60px;
}

.section-2 .wrapper h2 {
  font-size: 5em;
  font-weight: 200;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  line-height: 0.9em;
  padding: 0;
  margin-bottom: 20px;
  color: #05309c;
}

.section-2 .wrapper ul {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.6em;
  padding: 20px 20px;
  font-family: 'Roboto Flex', sans-serif;
 color: #1e3d4d;
}

.section-3 .wrapper h2 {
  font-size: 5em;
  font-weight: 200;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  line-height: 0.9em;
  padding: 0;
  margin-bottom: 20px;
  color: #05309c;
}

.section-3 {
  align-items: center;
  justify-content: flex-end;
  padding-right: 4em;
  gap: 40px;
}

.numbers-title {
  width: 600px;
  margin-top: -180px;
}

.numbers-title h2 {
  font-size: 3.5em;
  font-weight: 200;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  line-height: 0.9em;
  padding: 0;
  margin-bottom: 20px;
  color: #05309c;
}

.card-wrapper {
  display: flex;
  width: 600px;
  gap: 20px;
}

.card {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px 30px;
  border-radius: 20px;
  backdrop-filter: blur(10px) saturate(2) contrast(1.3);
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
}

.card h2 {
  font-size: 5em;
  font-weight: 300;
  letter-spacing: -0.06em;
  line-height: 1em;
  margin: 0.2em 0;
  color: #05309c;
}

.card p {
   font-size: 1em;
 color: #1e3d4d;
}

footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
 padding: 0 5em; /* Add padding to left and right (2em), no padding on top and bottom */
  height: auto; /* Remove height: 1em and let content determine height */
  color: white;
  background-color: #4290c8;
}

.footer-left h3 {
  text-transform: uppercase;
  font-size: 1.5em; /* Font size */
  line-height: 1.2; /* Adjust line height for more compact text */
  margin: 0; /* Remove any default margin on the <h3> */
  padding: 0;
}

.social {
  display: flex;
  gap: 8px; /* Reduce gap between social icons */
  font-size: 0.5em; /* Icon size */
  margin: 0; /* Ensure there's no margin causing extra space */
}


.footer-left p.copyright {
  font-size: 0.8em; /* Reduce font size of copyright text */
  text-transform: unset;
  font-weight: 200;
  margin-top: 0; /* Remove margin to decrease space */
}

.footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px; /* Reduce gap between items */
  font-size: 0.8em; /* Decrease font size slightly */
  font-weight: 300;
}

.footer-right ul {
  line-height: 2em; /* Adjust line height for tighter spacing */
  list-style: none;
  text-align: right;
}

/* MOBILE ADJUSTMENTS */
@media only screen and (max-width: 1200px) {
  .main-wrapper {
    width: 100%;
  }

  .section-1 .wrapper h2 {
    font-size: 2em;
  }

  .section-2 .wrapper p {
    font-size: 1.3em;
  }

  .section-3 .wrapper p {
    font-size: 1.3em;
  }

  .wrapper p {
    padding: 20px;
  }

  .section-2 .wrapper h2 {
    font-size: 2em;
  }

  .section-3 .wrapper h2 {
    font-size: 2em;
  }

  header ul {
    display: none;
  }

  .section-2 {
    width: 90%;
    padding-left: 2em;
    justify-content: center;
  }

  .section-2 .wrapper ul {
    font-size: 1em;
  }

  .section-3 .wrapper ul {
    font-size: 1em;
  }

  .section-3 {
    align-items: center;
    flex-direction: column-reverse;
    justify-content: center;
    padding-right: 1em;
    padding-left: 1em;
    gap: 40px;
  }

  /* Corrected selector here
  .section-4 {
    justify-content: flex-start;
    padding-left: 4em;
    align-items: center;
  }

  .section-4 .wrapper {
    margin-top: 60px;
    width: 600px;
  }

  .section-4 .wrapper h2 {
    font-size: 4.5em;
    font-weight: 200;
    letter-spacing: -0.05em;
    text-transform: uppercase;
    line-height: 0.9em;
    padding: 0;
    margin-bottom: 20px;
    color: #05309c;
  }

  .section-4 .wrapper ul {
    font-weight: 300;
    line-height: 1.6em;
    padding: 20px 20px;
    font-family: 'Roboto Flex', sans-serif;
  }

  .section-4 .wrapper p {
    font-size: 1em;
  }

  .section-4 .wrapper h2 {
    font-size: 2em;
  }

  .section-4 {
    width: 90%;
    padding-left: 2em;
    justify-content: center;
  }

  .section-4 .wrapper ul {
    font-size: 0.9em;
  }

  .section-4 .wrapper p {
    padding: 0;
    margin: 0;
    font-size: 1em;
  } */

  .section-2 .wrapper p {
    padding: 0;
    margin: 0;
    font-size: 1em;
  }

  .section-3 .wrapper p {
    padding: 0;
    margin: 0;
    font-size: 1em;
  }

  .card-wrapper {
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }

  .card {
    background-color: rgba(255, 255, 255, 0.7);
    padding: 10px 30px;
    border-radius: 20px;
    backdrop-filter: blur(10px) saturate(2) contrast(1.3);
  }

  .card h2 {
    font-size: 4em;
  }

  .card p {
    margin: 0;
    padding: 20px 0;
  }

  .numbers-title {
    width: 90%;
    margin-top: 0;
  }

  footer {
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    align-items: center;
    padding: 2em;
    margin-top: 6em;
  }

  .footer-left h3,
  .footer-left p {
    font-size: 2em;
  }

  .footer-right {
    display: none;
  }
}
